import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { FancyChip } from '../FancyChip.tsx'
import { Logo } from '../LogoZH.tsx'
import MyMarkdown from './Markdown.tsx'
import { Tooltip } from './Tooltip.tsx'

export const Header = (props: {
  privacy?: string
  slogan: boolean | string
  title?: string
  maintenance?: boolean
}) => {
  const [showBanner, setShowBanner] = useState(true)
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <header className="header">
      {props.privacy && showBanner && (
        <div
          className=" "
          style={{
            backgroundColor: 'var(--color-primary-700)',
            zIndex: 1000,
            color: 'white',
            width: '100vw',
            position: 'relative',
            padding: '0.5em',
            margin: '0em',
          }}
        >
          <div className={'container'} style={{ padding: '0 2em' }}>
            <div className={'privacy '}>
              <MyMarkdown markdown={props.privacy ? props.privacy : ''} />
              <button
                style={{
                  position: 'absolute',
                  right: '1rem',

                  top: '0',
                  background: 'none',
                  border: 'none',
                  color: 'white',
                  cursor: 'pointer',
                }}
                onClick={() => setShowBanner(false)}
                aria-label="Close banner"
              >
                ✕
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="header__text">
          <h1 className="h1">{props.title || t('header.title')}</h1>
          {props.slogan === true && (
            <div className="slogan h4">
              <Trans i18nKey="header.slogan">
                I am SomeGPT and I know (almost) everything published on
                <a href={import.meta.env.VITE_MAIN_SITE_LINK} target={'_blank'}>
                  someurl.chl
                </a>
                .
              </Trans>
            </div>
          )}
          {props.maintenance && (
            <div style={{ backgroundColor: 'var(--color-primary-100)', padding: '1em' }}>
              {' '}
              <h3>Maintenance Mode</h3>
              <div>{import.meta.env.VITE_SITE_NAME} is currently undergoing maintenance. Please come back later.</div>
            </div>
          )}
          {props.slogan !== false && props.slogan !== true && (
            <div className="slogan h4">
              <MyMarkdown markdown={props.slogan} />
            </div>
          )}
        </div>
        <div className="header__illustration">
          <div className="illustration">
            <div
              className="illustration__logo"
              onClick={() => {
                const searchParams = new URLSearchParams(window.location.search)
                const newSearchParams = new URLSearchParams()
                if (searchParams.has('q')) {
                  newSearchParams.set('q', searchParams.get('q') || '')
                }
                navigate(`/?${newSearchParams.toString()}`)
                window.location.reload()
              }}
            >
              <Logo />
            </div>
            <div className="illustration__background">
              <FancyChip />
            </div>
          </div>
        </div>
      </div>

      <Tooltip />
    </header>
  )
}
